import type { FC } from 'react'
import { memo, useEffect } from 'react'

import { Show } from 'common/components/Show/Show'
import { useAppDispatch } from 'common/hooks/redux'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import { ShopCardList } from 'features/Home/Book/Booking/Shop/ShopCardList/ShopCardList'
import { INFO_CONSTANTS } from 'features/Home/constants/infoConstants'
import {
  useLazyFetchLeaseShopQuery,
  useLazyFetchLeaseStatusQuery,
} from 'features/Home/Book/state/api/bookApi'
import { OptionOverlay, SET_OVERLAY_OPTION, TOGGLE_IS_OPEN_OPTION_OVERLAY } from 'features/Option'

import styles from './shop.module.scss'
import { useParams } from 'react-router-dom'

type Props = {
  isPromotion?: boolean
}

export const Shop: FC<Props> = memo(({ isPromotion = false }) => {
  const dispatch = useAppDispatch()

  const { bookingId, siteId } = useParams()

  const [fetchShopItems, { data: shopItems, isLoading }] = useLazyFetchLeaseShopQuery()
  const [fetchLeaseStatus, { data: leaseStatus }] = useLazyFetchLeaseStatusQuery()

  useEffect(() => {
    if (!bookingId || !siteId) {
      return
    }

    fetchLeaseStatus({ bookingId, siteId })
    fetchShopItems({ bookingId, siteId })
  }, [bookingId, siteId])

  const handleAddToCartShopItem = (shopItem: ISiteRoom): void => {
    dispatch(SET_OVERLAY_OPTION({ ...shopItem, isPromotion }))
    dispatch(TOGGLE_IS_OPEN_OPTION_OVERLAY())
  }

  return (
    <div className={styles.parent}>
      <div className={styles.parentInfo}>
        <h1>{INFO_CONSTANTS.SHOP}</h1>
        <Show when={leaseStatus}>
          <span className={styles.parentInfoStatus}>{leaseStatus}</span>
        </Show>
      </div>
      <p className={styles.parentInfoDescription}>{INFO_CONSTANTS.SHOP_DESCRIPTION}</p>
      <ShopCardList
        isLoading={isLoading}
        shopItems={shopItems || []}
        handleSelectShopItem={handleAddToCartShopItem}
      />
      <OptionOverlay />
    </div>
  )
})
