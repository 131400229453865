import { Fragment, type FC } from 'react'

import type { IRoomSelectedDay, IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { CartRoomDay } from 'features/Cart/components/CartRoomDay/CartRoomDay'
import { CartError } from 'features/Cart/components/CartError/CartError'
import { DaySummary } from 'features/Cart/components/DaySummary/DaySummary'

import styles from './cartRoomDayList.module.scss'

interface IProps {
  reservedDays: IRoomSelectedDay[]
  handleRemoveSlot: (slot: IRoomTimeSlot | IRoomTimeSlot[], date: string) => void
}

export const CartRoomDayList: FC<IProps> = ({ reservedDays, handleRemoveSlot }) => {
  const handleRemoveDay = (day: IRoomSelectedDay): void => {
    const slots = day.selectedSlots.map((slot) => slot)
    handleRemoveSlot(slots, day.date)
  }

  return (
    <div className={styles.parent}>
      {reservedDays.map((day: IRoomSelectedDay, index: number) => {
        const firstError = day?.selectedSlots.find((slot) => slot?.error_message)
        return (
          <Fragment key={`${day.date}-${index}`}>
            <DaySummary date={day.date} onRemoveDay={() => handleRemoveDay(day)}>
              <CartRoomDay day={day} handleRemoveSlot={handleRemoveSlot} />
            </DaySummary>
            <CartError error={day?.error_message ?? firstError?.error_message} />
          </Fragment>
        )
      })}
    </div>
  )
}
