export enum AUTH_CONSTANTS {
  MAIN_WEBSITE_URL = 'https://www.residen.com/',
  LOG_IN = 'Log In',
  NOT_OK = 'Not Ok',
  EMAIL = 'Email',
  USER = 'User',
  TYPE_EMAIL = 'Type in your email address',
  TYPE_PASSWORD = 'Type in your account’s password',
  TYPE_PASSWORD_NEW = 'Type in your password',
  RETYPE_PASSWORD = 'Type in the same password again',
  PASSWORD = 'Password',
  REGISTER = 'Sign Up',
  FORGOT_PASSWORD = 'Forgot Password?',
  LOG_IN_TO = 'Log In to your Account',
  DONT_HAVE_ACCOUNT = "Don't have an account? ",
  CONTINUE_AS_GUEST = 'Continue as Guest to browse prices',
  SAVE_CONTINUE = 'Save & Continue',
  CHECK_EMAIL = 'Check your Email',
  CREATE_NEW_PASSWORD = 'Create new password',
  PASSWORD_UPDATED = 'Your password has been set up successfully',
  UPDATED_DETAILS = 'We have sent a password recovery instruction to your email. Please click on that link to verify your account',
  PASSWORD_DETAILS = 'Enter the email address associated with your account',
  VALIDATE_EMAIL = 'Please validate your email address, click the "Verify Email Address" button.',
  VERIFY_SPAM = 'Please be sure to verify your spam folder as well, as some email clients might mark our email as spam.',
  REGISTER_TITLE = 'Sign Up for an Account',
  HAVE_ACCOUNT = 'Already have an account? ',
  CONFIRM_PASSWORD = 'Confirm password',
  DETAILS_CONFIRM = 'By signing up, you confirm that you’ve read and accepted our ',
  TERM_CONDITIONS = 'Terms & Conditions',
  OK = 'Ok',
  CONGRATULATIONS = 'Congratulations',
  VALIDATE_IDENTITY = 'Validate Credentials',
  THANK_YOU_START = 'Thank you for joining us! Before continuing please validate your medical credentials',
  THANK_PROCESSING = 'Thank you for uploading your documents. You will be notified by email when the validation process is done',
  VALIDATING_DOCUMENTS = 'Validating documents',
  VALIDATION_COMPLETED = 'Validation completed',
  SEEMS_OK = 'It seems everything is ok',
  GO_HOME = 'Go to Home',
  RESET_PASSWORD = 'Reset Password',
  SUBMIT_NEW = 'Submit new files',
  PENDING = 'Pending',
}
