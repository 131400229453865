export enum NEW_PATIENT_LABELS {
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
  PHONE = 'Mobile Phone',
  LANGUAGE = 'Language',
  ROOM = 'Room',
  APPOINTMENT_TIME = 'Appointment time',
  APPOINTMENT_WITH = 'Appointment with',
  TEMPERATURE_BYPASS = 'Bypass temperature check',
  PROCEDURE = 'Procedure',
}
