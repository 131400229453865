import type { FC, JSX } from 'react'

import classNames from 'classnames'

import { Show } from 'common/components/Show/Show'
import { UtilService } from 'common/services/utilService'

import type {
  IRoomOptionProduct,
  IRoomSelectedTimeSlot,
  IRoomTimeSlot,
} from 'features/Booking/interfaces/ILeaseInfoRoom'
import { CartCollapse } from 'features/Cart/components/CartCollapse/CartCollapse'
import { CART_CONSTANTS } from 'features/Cart/constants/cartConstants'

import styles from './cartRoomSlot.module.scss'

interface IProps {
  date: string
  timeSlot: IRoomSelectedTimeSlot
  handleRemoveSlot: (slot: IRoomTimeSlot, date: string) => void
}

export const CartRoomSlot: FC<IProps> = ({ date, timeSlot, handleRemoveSlot }) => {
  return (
    <CartCollapse
      error={timeSlot?.error_slot || timeSlot?.error_message}
      errorList={timeSlot?.error_list}
      additionalActiveTitle={timeSlot.title}
      title={timeSlot.title}
      handleRemoveSlot={() => handleRemoveSlot(timeSlot, date)}>
      <Show when={timeSlot?.free_seat}>
        <div className={classNames(styles.parent, styles.parentFree)}>
          <span>{CART_CONSTANTS.WAITING_CHAIR_FREE}</span>
          <span>{CART_CONSTANTS.FREE}</span>
        </div>
      </Show>
      <div className={styles.parent}>
        Room
        <span>{UtilService.numberToDollar(timeSlot.price)}</span>
      </div>
      <Show when={!!timeSlot?.selectedOptions?.length}>
        {timeSlot?.selectedOptions?.map(
          (option: IRoomOptionProduct, index: number): JSX.Element => {
            return (
              <div className={styles.parent} key={index}>
                <Show
                  when={option?.stock}
                  fallback={
                    <>
                      {option.name}
                      <span>{UtilService.numberToDollar(option.price)}</span>
                    </>
                  }>
                  {option.name} {option.stock} x {UtilService.numberToDollar(option.price)}
                  <span>{UtilService.numberToDollar(option.price * option.stock)}</span>
                </Show>
              </div>
            )
          },
        )}
      </Show>
    </CartCollapse>
  )
}
