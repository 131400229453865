export enum CART_CONSTANTS {
  TOTAL = 'Total',
  BONUS = 'BONUS',
  CART_EMPTY = 'Your cart is currently empty',
  RENT_BACK = 'Book back to back clinics and get 30 mins on us',
  CONFIRM_CLEAR_MESSAGE = 'Your shopping cart will be emptied if you change your location.',
  SIGN_IN_THE_BOX = 'Please sign in the box below to indicate that you have read and agree to the contract above',
  SIGN_PROMOTIONS = 'Please sign in the box below to indicate that you have read and agree to all contracts above',
  AGREE_WITH = 'I agree to the',
  AGREE_WITH_USPS_FROM = 'I agree with USPS FORM 1583',
  STIPULATED_DEPOSIT = 'deposit stipulated in the contract',
  PURCHASES = 'Purchases',
  LEASE_ID = 'Booking ID',
  QUANTITY = 'Quantity: ',
  WAITING_CHAIR_FREE = 'Waiting Room Chair x 1',
  FREE = 'FREE',
  CART_LIMIT = 'Payment limit is set to ',
  INVALID_ROOM_ID = 'Invalid room ID. Acceptable range is from 1 to 26',
  SELECTION_TOTAL = 'Total',
  START_DATE = 'Start date:',
  END_DATE = 'End date:',
}
