import { type FC, type ReactNode } from 'react'
import dayjs from 'dayjs'

import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import { UtilService } from 'common/services/utilService'
import trashIcon from 'features/Cart/assets/trash.svg'

import styles from './daySummary.module.scss'

interface IProps {
  date: string
  children: ReactNode
  onRemoveDay: () => void
}

export const DaySummary: FC<IProps> = ({ date, children, onRemoveDay }) => {
  return (
    <div className={styles.parent}>
      <div className={styles.parentContent}>
        <details className={styles.parentDetails}>
          <summary className={styles.parentSummary}>
            <span className={styles.parentTitle}>
              {dayjs(date).format(DATE_FORMAT.CREATE_LEASE)}
            </span>
          </summary>
          {children}
        </details>

        <button
          className={styles.parentRemoveButton}
          onClick={onRemoveDay}
          data-cy={`delete-day-${UtilService.replaceSpaceToUnderscore(date)}`}>
          <img
            src={trashIcon}
            alt={ALT_CONSTANTS.ACTION_ICON}
            className={styles.parentRoomRemoveIcon}
          />
        </button>
      </div>
    </div>
  )
}
