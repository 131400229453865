import { isValidPhoneNumber } from 'react-phone-number-input'

import * as Yup from 'yup'

import { RegexService } from 'common/services/regexService'

export const SETTINGS_PROFILE_STAFF_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required field')
    .matches(RegexService.email(), 'Please enter your valid email'),
  first_name: Yup.string()
    .required('First Name is required field')
    .matches(RegexService.onlyLatin(), 'First name can only contain latin letters'),
  last_name: Yup.string()
    .required('Last Name is required field')
    .matches(RegexService.onlyLatin(), 'Last name can only contain latin letters'),
  phone_number: Yup.string()
    .nullable()
    .min(1, 'Please enter a valid mobile phone.')
    .test('is-valid-phone', 'Please enter a valid mobile phone', function (value) {
      return value && isValidPhoneNumber(value)
    })
    .required('Please enter the phone'),
})
