import type { FC } from 'react'
import { UtilService } from 'common/services/utilService'

import styles from './totalAmount.module.scss'

const { numberToDollar } = UtilService

interface IProps {
  price: number
}

export const TotalAmount: FC<IProps> = ({ price }) => {
  return (
    <div className={styles.parent}>
      <p>Subtotal:</p>
      <p>{numberToDollar(price)}</p>
    </div>
  )
}
