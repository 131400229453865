import { isValidPhoneNumber } from 'react-phone-number-input'

import * as Yup from 'yup'

import { RegexService } from 'common/services/regexService'

import { USA_COUNTRY_ID } from 'features/SettingsOld/constants/settingsAddCard'

export const SETTINGS_PROFILE_DOCTOR_SCHEMA = Yup.object().shape({
  additional_license: Yup.string().nullable(true),
  country_id: Yup.number().required().nullable(true),
  dea_license: Yup.string().nullable(true),
  email: Yup.string()
    .email()
    .required('Email is required field')
    .matches(RegexService.email(), 'Please enter your valid email'),
  mailing_zip_code: Yup.number()
    .typeError('Zipcode contains only numbers')
    .min(0, 'The input is not valid zipcode')
    .nullable(true),
  medical_license: Yup.string().required('Medical license is required field').nullable(),
  medical_license_state_code: Yup.number()
    .nullable(true)
    .when('country_id', {
      is: USA_COUNTRY_ID,
      then: Yup.number().required('State is required field'),
    }),
  first_name: Yup.string()
    .required('Please enter your first name')
    .min(2, 'First name must be at least 2 characters')
    .matches(RegexService.onlyLatin(), 'The input is not valid first name!'),

  last_name: Yup.string()
    .required('Please enter your last name')
    .min(2, 'Last name must be at least 2 characters')
    .matches(RegexService.onlyLatin(), 'The input is not valid last name!'),
  phone_number: Yup.string()
    .nullable()
    .min(1, 'Please enter a valid mobile phone.')
    .test('is-valid-phone', 'Please enter a valid mobile phone', function (value) {
      return value && isValidPhoneNumber(value)
    })
    .required('Please enter the phone'),
  office_phone: Yup.string()
    .nullable()
    .min(1, 'Please enter a valid mobile phone.')
    .test('is-valid-phone', 'Please enter a valid mobile phone', function (value) {
      return value && isValidPhoneNumber(value)
    })
    .required('Please enter the phone'),
  speciality: Yup.string().required('Speciality is required field').nullable(),
  qualification: Yup.string().nullable(),
  salutation: Yup.string().nullable(),

  current_password: Yup.string().when(['new_confirm_password'], {
    is: (new_password: string, new_confirm_password: string): boolean =>
      Boolean(new_password) || Boolean(new_confirm_password),
    then: (schema) => schema.required('Current password is required'),
  }),
})
