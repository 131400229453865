import compose from 'compose-function'

import { withAuth } from './withAuth'
import { withConnection } from './withConnection'
import { withErrorBoundary } from './withErrorBoundary'
import { withLocalStorageMonitor } from './withLocaleStorageMonitor'
import { withNotification } from './withNotification'
import { withRouter } from './withRouter'
import { withSentry } from './withSentry'
import { withStore } from './withStore'

export const withProviders = compose(
  withSentry,
  withErrorBoundary,
  withRouter,
  withStore,
  withNotification,
  withAuth,
  withConnection,
  withLocalStorageMonitor,
)
