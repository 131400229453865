import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'
import { Calendar } from 'common/components/Calendar/Calendar'
import { Show } from 'common/components/Show/Show'
import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { LeaseSlotList } from 'features/Booking/components/LeaseSlotList/LeaseSlotList'
import { LeaseSubmit } from 'features/Booking/components/LeaseSubmit/LeaseSubmit'
import type { IRoomSelectedDay, IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { getLeasePriceBySlots } from 'features/Booking/services/leaseService'
import { useLazyFetchRoomSlotsQuery } from 'features/Booking/state/api/bookingApi'
import {
  autoSaveSlotsAsync,
  FILTER_NEW_LEASE_SELECTED_SLOTS,
  TOGGLE_IS_CREATE_NEW_LEASE,
  UPDATE_NEW_LEASE_ROOM_DATE_INFO,
  UPDATE_NEW_LEASE_ROOM_INFO,
  UPDATE_NEW_LEASE_SELECTED_SLOT,
} from 'features/Booking/state/slices/leaseSlice'
import type { IOptionSlot } from 'features/Option'
import { SET_OPTION_TYPE_SLOTS } from 'features/Option'
import type { IRemoveSlotInOptionsProps } from 'features/Option/interfaces/IOptionService'
import { OptionService } from 'features/Option/services/optionService'

import styles from './leaseContent.module.scss'
import { useParams } from 'react-router-dom'
import BookingSlots from 'features/Booking/components/BookingSlots/BookingSlots'
import { type CalculatedDiscount } from 'features/Booking/components/BookingSlots/booking.utils'
import { formatMoney } from 'common/services/format'
import { shallowEqual } from 'react-redux'
import { Col, Row } from 'antd'
import { type IBookingOverlayProps } from 'features/Booking/interfaces/ILeaseOverlayProps'
import { Overlay } from 'common/components/Overlay'
import Popup from 'common/components/Popup'
import { useCartHasChanges } from './useCartHasChanges'
import { useCartManager } from 'features/Cart'
import { useAuth } from 'app/providers'

const { removeSlotInOptions } = OptionService

interface ButtonsContainerProps {
  calculatedDiscount: CalculatedDiscount
  totalDiscount: string
  leasePrice: number
  isOneHourSlot: boolean
}

const ButtonsContainer: FC<ButtonsContainerProps> = ({
  calculatedDiscount,
  totalDiscount,
  leasePrice,
  isOneHourSlot,
}) => {
  return (
    <div className={styles.buttonsContainer}>
      <Show when={!!calculatedDiscount.totalDiscount}>
        <span className={styles.discount}>{totalDiscount} discount applied at checkout</span>
        <hr />
      </Show>
      <LeaseSubmit price={leasePrice} isOneHourSlot={isOneHourSlot} />
    </div>
  )
}

export const LeaseContent: FC<IBookingOverlayProps> = ({
  fetchRoomCalendar,
  title,
  isCreateLease,
}) => {
  const dispatch = useAppDispatch()
  const hasCartChanges = useCartHasChanges()
  const { updateCartContent } = useCartManager()
  const { isUnconfirmedDoctor } = useAuth()

  const { siteId } = useParams()

  const { optionTypeSlots } = useAppSelector((state) => state.optionReducer)
  const { newLeaseInfo } = useAppSelector((state) => state.leaseReducer)

  const defaultSelectedSlotsIds = useAppSelector(
    (state) => state.leaseReducer?.newLeaseInfo?.selectedDay?.selectedSlots?.map((slot) => slot.id),
    shallowEqual,
  )

  const [calculatedDiscount, setCalculatedDiscount] = useState<CalculatedDiscount>({
    totalDiscount: 0,
    applicableDiscount: null,
  })

  const [fetchSlots, { data: slotsData, isFetching: isFetchingSlots }] =
    useLazyFetchRoomSlotsQuery()

  const { room, selectedDay, selectedDays, slots, days = {}, isLoading } = newLeaseInfo

  const handleChangeDate = async (date: string): Promise<void> => {
    if (!date) return
    dispatch(UPDATE_NEW_LEASE_ROOM_DATE_INFO({ date: date, selectedSlots: [], selectedSlot: null }))
    if (room?.id) {
      const response = await fetchSlots({ roomId: room.id, date, siteId })
      if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
        dispatch(UPDATE_NEW_LEASE_ROOM_INFO({ slots: response.data.slots }))
      }
    }
  }

  const handleRemoveTimeSlot = useCallback(
    (slot: IRoomTimeSlot): void => {
      const removeSlotProps: IRemoveSlotInOptionsProps = {
        slotId: slot.id,
        date: selectedDay.date,
        options: optionTypeSlots,
      }
      const optionSlots: IOptionSlot[] = removeSlotInOptions(removeSlotProps)
      dispatch(SET_OPTION_TYPE_SLOTS(optionSlots))
      dispatch(FILTER_NEW_LEASE_SELECTED_SLOTS(slot))
    },
    [selectedDay, optionTypeSlots],
  )

  const handleUpdateSlot = useCallback(
    (slot: IRoomTimeSlot): void => {
      dispatch(UPDATE_NEW_LEASE_SELECTED_SLOT(slot))
    },
    [selectedDay],
  )

  const handleCloseLeaseOverlay = () => {
    dispatch(TOGGLE_IS_CREATE_NEW_LEASE())
  }

  const adjustedSelectedDays = useMemo(
    (): string[] =>
      selectedDays
        ?.filter?.((day) => day?.selectedSlots?.length)
        .map((selectedDay: IRoomSelectedDay): string => selectedDay.date),
    [selectedDays],
  )

  const selectedCalendarDate = useMemo((): string => {
    if (selectedDay?.date) return selectedDay?.date

    if (!Object.keys(days ?? {}).length) return null

    const tomorrow: string = dayjs().format('YYYY-MM-DD')
    let nextActiveDate: string | null = null

    for (
      let date: string = tomorrow;
      date in days;
      date = dayjs(date).add(1, 'days').format('YYYY-MM-DD')
    ) {
      if (days[date].active === 1) {
        nextActiveDate = date
        break
      }
    }

    return nextActiveDate
  }, [selectedDay?.date, days])

  const isOneHourSlot = useMemo(() => {
    return slotsData?.slots?.some((slot) => !!slot?.group_time)
  }, [slotsData])

  const leasePrice = useMemo(() => {
    if (!isOneHourSlot) {
      // old logic
      if (!selectedDay || !selectedDay?.selectedSlot) return 0
      return getLeasePriceBySlots([selectedDay.selectedSlot])
    }
    // new logic
    if (!selectedDays?.length) {
      return 0
    }
    return selectedDays.reduce((totalPrice, currentDate) => {
      const currentDatePrice = getLeasePriceBySlots(currentDate?.selectedSlots)
      return totalPrice + currentDatePrice
    }, 0)
  }, [selectedDay, isOneHourSlot])

  const totalDiscount = useMemo(() => {
    return formatMoney(calculatedDiscount.totalDiscount)
  }, [calculatedDiscount.totalDiscount])

  const fetchRoomCalendarFirstTime = () => {
    let findSelectedDay = selectedDay?.date
    if (!findSelectedDay) {
      const firstDay = selectedDays?.at(0)?.date
      findSelectedDay ??= firstDay
    }
    findSelectedDay ??= dayjs().format('YYYY-MM-DD')
    handleChangeDate(findSelectedDay)
    fetchRoomCalendar(room.id, dayjs().format('YYYY-MM'))
  }

  const autoSaveSlots = useCallback(async () => {
    const updatedLease = await dispatch(autoSaveSlotsAsync()).unwrap()
    await updateCartContent({ rooms: updatedLease })
    handleCloseLeaseOverlay()
  }, [])

  function onCloseOverlay() {
    if (!hasCartChanges || isUnconfirmedDoctor) {
      return handleCloseLeaseOverlay()
    }

    Popup.showInfo(
      {
        title: 'Are you sure you want to leave?',
        message: 'You have unsaved changes. Your changes will be lost.',
        mainButtonText: 'Save',
        secondButtonText: 'Yes',
      },
      () => autoSaveSlots(),
      () => handleCloseLeaseOverlay(),
    )
  }

  useEffect(() => {
    if (room?.id && isCreateLease) {
      fetchRoomCalendarFirstTime()
    }
  }, [room?.id, isCreateLease])

  return (
    <Overlay title={title} isOpen={isCreateLease} onClose={() => onCloseOverlay()}>
      <div className={styles.overlayParent}>
        <div className={styles.parent}>
          <Row gutter={[32, 12]}>
            <Col flex='none'>
              <Calendar
                days={days ?? {}}
                withFakeRerender
                isLoading={isLoading}
                handleChangeDate={handleChangeDate}
                selectedDays={adjustedSelectedDays}
                handleChangeMonth={fetchRoomCalendar}
                currentDay={selectedCalendarDate}
                isStrictDisable
              />
              <div className={styles.actionButtonsTop}>
                <ButtonsContainer
                  calculatedDiscount={calculatedDiscount}
                  totalDiscount={totalDiscount}
                  leasePrice={leasePrice}
                  isOneHourSlot={isOneHourSlot}
                />
              </div>
            </Col>
            <Col flex='auto'>
              <div className={styles.parentSelectedSlot}>
                <Show when={selectedDay?.date}>
                  <h5 className={styles.parentSelectedDay}>
                    {dayjs(selectedDay?.date || selectedCalendarDate).format(
                      DATE_FORMAT.SELECTED_DATE_LABEL,
                    )}
                  </h5>
                </Show>

                <Show when={!isOneHourSlot}>
                  <LeaseSlotList
                    slots={slots.flat()}
                    isFetching={isFetchingSlots}
                    currentSelected={selectedDay?.selectedSlot}
                    handleSelectActiveSlot={handleUpdateSlot}
                    handleConfirmActiveSlot={handleUpdateSlot}
                    handleRemoveTimeSlot={handleRemoveTimeSlot}
                    selectedSlots={selectedDay?.selectedSlots || []}
                  />
                </Show>
                <Show when={isOneHourSlot}>
                  <BookingSlots
                    setCalculatedDiscount={setCalculatedDiscount}
                    date={selectedCalendarDate}
                    isLoading={isFetchingSlots}
                    defaultSelectedSlotsIds={defaultSelectedSlotsIds}
                  />
                </Show>
              </div>
            </Col>
            <Col flex='auto' className={styles.actionButtonsBottom}>
              <ButtonsContainer
                calculatedDiscount={calculatedDiscount}
                totalDiscount={totalDiscount}
                leasePrice={leasePrice}
                isOneHourSlot={isOneHourSlot}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Overlay>
  )
}
