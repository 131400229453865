import type { FC } from 'react'
import { memo, useCallback, useState } from 'react'
import type { Country, Value } from 'react-phone-number-input'
import PhoneInputWithCountrySelect, { parsePhoneNumber } from 'react-phone-number-input'

import { Skeleton } from 'antd'
import clsx from 'classnames'

import { Label } from 'common/components/Label/Label'
import { PhoneInputSelect } from 'common/components/PhoneInput/PhoneInputSelect/PhoneInputSelect'
import { Show } from 'common/components/Show/Show'
import { INPUT_PLACEHOLDER } from 'common/constants/inputConstants'

import 'react-phone-number-input/style.css'
import './phoneInput.css'
import styles from './phoneInput.module.scss'

interface IProps {
  value?: Value | string
  border?: boolean
  label?: string | JSX.Element
  disabled?: boolean
  isLoading?: boolean
  placeholder?: string
  onChange?: (value: Value | null) => void
  onBlur?: () => void
  onResetCountry?: (resetFunc: () => void) => void
  error?: string
  required?: boolean
  containerClassName?: string
  inputClassName?: string
}

const PhoneInput: FC<IProps> = ({
  onChange,
  onBlur,
  onResetCountry = () => {},
  value,
  disabled = false,
  isLoading,
  placeholder,
  border = true,
  label,
  error = '',
  required,
  containerClassName,
  inputClassName,
}) => {
  const [defaultCountry, setDefaultCountry] = useState<Country>('US')
  const resetDefaultCountry = useCallback(() => {
    setDefaultCountry('US')
  }, [])

  onResetCountry(resetDefaultCountry)
  const handleChange = (number: Value): void => {
    if (number) onChange(number)
    else onChange(null)
  }

  const phoneNumber = parsePhoneNumber(value || '')

  const handleCountryChange = (code: Country): void => {
    setDefaultCountry(code || defaultCountry)
  }

  return (
    <div className={styles.phoneInputWrapper}>
      {label && <Label label={label} required={required} />}
      <div
        className={clsx(
          styles.phoneInputContainer,
          { [styles.borderOff]: !border },
          containerClassName,
        )}>
        {isLoading ? (
          <Skeleton.Input active className={styles.phoneInputSkeleton} />
        ) : (
          <PhoneInputWithCountrySelect
            className={inputClassName}
            onBlur={onBlur}
            onChange={handleChange}
            placeholder={placeholder || INPUT_PLACEHOLDER.TYPE_HERE}
            addInternationalOption={false}
            defaultCountry={defaultCountry}
            disabled={disabled || isLoading}
            value={phoneNumber?.number || value}
            onCountryChange={handleCountryChange}
            countrySelectComponent={(data: object) => (
              <PhoneInputSelect data={data} isLoading={isLoading} country={defaultCountry} />
            )}
          />
        )}
      </div>
      <Show when={error}>
        <p className='errorMessage'>{error}</p>
      </Show>
    </div>
  )
}

// @ts-ignore: Mini hack for prevent dropdown closing
export default memo(
  PhoneInput,
  (prevProps: Readonly<IProps>, nextProps: Readonly<IProps>): boolean => {
    const isDiffValue: boolean = prevProps.value !== nextProps.value
    const isDiffDisabled: boolean = prevProps.disabled !== nextProps.disabled
    const isDiffIsLoading: boolean = prevProps.isLoading !== nextProps.isLoading
    const isDiffError: boolean = prevProps.error !== nextProps.error

    const rerenderDeps: boolean[] = [isDiffValue, isDiffDisabled, isDiffIsLoading, isDiffError]

    if (rerenderDeps.includes(true)) return false
    return true
  },
)
