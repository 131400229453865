import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { useAuth } from 'app/providers'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ICartOptionContent } from 'common/interfaces/ICartContent'
import type { ApiResponse } from 'common/interfaces/IRequestResponse'

import { SET_NEW_LEASE_ROOMS } from 'features/Booking'
import type { BOOKING_TYPE } from 'features/Booking/constants/booking.constants'
import { BOOKING_TYPE_CODE } from 'features/Booking/constants/booking.constants'
import {
  UPDATE_CART_CONTENT,
  UPDATE_CART_CONTENT_DATA,
  useCreateCartMutation,
  useDeleteCartMutation,
  useLazyFetchCartQuery,
  useUpdateCartMutation,
} from 'features/Cart'
import type { ICartData, ICartOptionData } from 'features/Cart/interfaces/ICart'
import {
  useCreateSingleCartMutation,
  useDeleteSingleCartMutation,
  useLazyFetchCartsQuery,
  useUpdateSingleCartMutation,
} from 'features/Cart/state/api/cartApi'
import {
  TOGGLE_IS_OPEN_CART_DETAILS,
  UPDATE_CART_CONNECTION,
  updateActiveBooking,
  updateCartState,
  updateLeaseConnection,
  updateSingleCart,
} from 'features/Cart/state/slices/cartSlice'
import { SET_OPTION_TYPE_SLOTS } from 'features/Option'
import {
  SET_OPTION_TYPE_SELECT_FIXED,
  UPDATE_OPTION_TYPE_FIXED,
} from 'features/Option/state/slices/optionSlice'
import { makeLeaseRoomsGreatAgain } from 'features/Booking/services/leaseService'
import { useLazyFetchLevelsQuery } from 'common/store/api/rootApi'

export const useCartManager = () => {
  const dispatch = useAppDispatch()

  const { processApiResponse } = useApiResponse()
  const { siteId } = useParams()

  const [deleteCart] = useDeleteCartMutation({
    fixedCacheKey: 'deleting-cart',
  })

  const { cart, activeBooking } = useAppSelector((state) => state.cartReducer)
  const { optionShopItems } = useAppSelector((state) => state.optionReducer)
  const {
    [BOOKING_TYPE_CODE.BOOKING]: bookingCart,
    [BOOKING_TYPE_CODE.STORAGE]: storageCart,
    [BOOKING_TYPE_CODE.MAILBOX]: mailboxCart,
    ...recurringCarts
  } = cart ?? {}

  const [deleteSingleCart] = useDeleteSingleCartMutation({
    fixedCacheKey: 'deleting-single-cart',
  })
  const [createSingleCart] = useCreateSingleCartMutation()
  const [editSingleCart] = useUpdateSingleCartMutation()

  const [updateCart] = useUpdateCartMutation({
    fixedCacheKey: 'updating-cart',
  })

  const [createCart] = useCreateCartMutation({
    fixedCacheKey: 'creating-cart',
  })
  const [fetchCart] = useLazyFetchCartQuery({
    refetchOnReconnect: true,
    refetchOnFocus: true,
  })

  const [fetchCarts] = useLazyFetchCartsQuery({
    refetchOnReconnect: true,
    refetchOnFocus: true,
  })

  const [fetchLevels] = useLazyFetchLevelsQuery()

  const auth = useAuth()

  const { user } = auth ?? {}

  const { newLeaseRooms } = useAppSelector((state) => state.leaseReducer)
  const { optionTypeSlots, optionTypeFixed, optionSelectFixed } = useAppSelector(
    (state) => state.optionReducer,
  )

  const prevNewLeaseRooms = usePrevious(newLeaseRooms)
  const navigate = useNavigate()

  const updateAppStore = (userCart: ICartOptionContent): void => {
    const {
      rooms = [],
      optionFixed = [],
      optionSlots = [],
      optionSelectFixed = [],
    } = userCart?.data ?? {}

    dispatch(UPDATE_CART_CONTENT(userCart))
    dispatch(SET_NEW_LEASE_ROOMS(rooms))
    dispatch(UPDATE_OPTION_TYPE_FIXED(optionFixed))
    dispatch(SET_OPTION_TYPE_SLOTS(optionSlots))
    dispatch(SET_OPTION_TYPE_SELECT_FIXED(optionSelectFixed))
  }

  const fetchCartsData = async (): Promise<void> => {
    if (!user) return
    const response = await fetchCarts(siteId)
    processApiResponse(response as ApiResponse, {
      successCallback: () => {
        const responseData = response.data
        updateAppStore(responseData[BOOKING_TYPE_CODE.BOOKING])
        fetchLevels()
        dispatch(updateCartState({ key: 'cart', value: responseData }))
      },
    })
  }

  function updateActiveLeaseConnection(siteId: string | null) {
    dispatch(updateLeaseConnection(siteId))
  }

  function setActiveBooking(data: {
    bookingType: BOOKING_TYPE
    requestId?: number
    visible: boolean
  }) {
    dispatch(updateActiveBooking(data))
  }

  const fetchCartData = async (): Promise<void> => {
    if (!user) return
    const response = await fetchCart(siteId)
    processApiResponse(response as ApiResponse, {
      successCallback: () => {
        updateAppStore(response.data as ICartOptionContent)
      },
    })
  }

  const updateLocaleState = (data: ICartOptionData | null): void => {
    dispatch(SET_NEW_LEASE_ROOMS(data?.rooms || []))
    dispatch(UPDATE_CART_CONTENT_DATA(data))
  }

  const handleDeleteCart = async (): Promise<void> => {
    const response = await deleteCart(siteId)
    processApiResponse(response as ApiResponse, {
      displayError: false,
      successCallback: (): void => {
        updateAppStore(null)
      },
    })
  }

  const cartAmount = useMemo(() => {
    return (
      (mailboxCart?.data?.amount?.subtotal || 0) +
      (storageCart?.data?.amount?.subtotal || 0) +
      (bookingCart?.data?.amount?.subtotal || 0) +
      Object.values(optionShopItems || {}).reduce(
        (acc, curr) => acc + (curr?.amount?.subtotal || 0),
        0,
      ) +
      Object.values(recurringCarts || {}).reduce(
        (acc, { data }) => acc + (data?.amount?.subtotal || 0),
        0,
      )
    )
  }, [bookingCart, mailboxCart, storageCart, recurringCarts, optionShopItems])

  const handleDeleteSingleCart = async (requestId: number): Promise<void> => {
    const response = await deleteSingleCart({ request_url_id: requestId, siteId })
    processApiResponse(response as ApiResponse, {
      successCallback: (): void => {
        dispatch(updateSingleCart({ key: requestId, data: null }))
        navigate('/')
      },
    })
  }

  async function handleCreateSingleCart(
    data: Partial<ICartOptionData>,
    siteId: string,
    request_url_id: number = activeBooking.requestId,
    callback?: (rs?: ICartOptionContent) => void,
  ) {
    const response = await createSingleCart({
      data,
      siteId,
      request_url_id,
    })

    processApiResponse(response as ApiResponse, {
      successCallback: () => {
        dispatch(updateSingleCart({ key: request_url_id, data: response.data }))
        callback && callback(response.data)
      },
    })
  }

  async function handleUpdateSingleCart(
    data: Partial<ICartOptionData>,
    request_url_id: number = activeBooking?.requestId,
    callback?: (rs?: ICartOptionContent) => void,
  ) {
    const response = await editSingleCart({
      data,
      request_url_id,
      siteId,
    })

    processApiResponse(response as ApiResponse, {
      successCallback: () => {
        dispatch(updateSingleCart({ key: request_url_id, data: response.data }))
        callback && callback(response.data)
      },
    })
  }

  function toggleMainCart() {
    dispatch(TOGGLE_IS_OPEN_CART_DETAILS())
  }

  const updateCartContent = async (cartData: ICartData): Promise<void> => {
    const isEmptyCart: boolean = !bookingCart?.data
    const isEmptyRoomsData: boolean = !cartData?.rooms?.length
    const hasSlots = cartData?.rooms
      ?.flatMap((room) => room.selectedDays ?? [])
      .some((day) => day?.selectedSlots?.length > 0)

    const isValidCart: boolean = !!Object.values(cartData).flat()?.length
    const isDeleteRequest: boolean = !isValidCart || isEmptyRoomsData || !hasSlots
    const isCreateRequest: boolean = isEmptyCart && !!cartData?.rooms?.length
    const isUpdateRequest: boolean = !isEmptyCart && isValidCart && !isEmptyRoomsData && hasSlots

    switch (true) {
      case isUpdateRequest:
        const updated = {
          ...cartData,
          // optionSelectFixed: cartData?.optionSelectFixed?.length
          //   ? cartData.optionSelectFixed
          //   : optionSelectFixed,
          // optionFixed: makeOptionsGreatAgain(
          //   cartData?.optionFixed?.length ? cartData.optionFixed : optionTypeFixed,
          // ),
          // optionSlots: makeOptionsGreatAgain(
          //   cartData?.optionSlots?.length ? cartData.optionSlots : optionTypeSlots,
          // ),
          rooms: makeLeaseRoomsGreatAgain(cartData.rooms),
        }

        const responseUpdate = await updateCart({ data: updated, siteId })
        processApiResponse(responseUpdate as ApiResponse, {
          error: 'Update cart error',
          errorCallback: () => dispatch(SET_NEW_LEASE_ROOMS(prevNewLeaseRooms)),
          successCallback: () => {
            const { data: responseData } = responseUpdate

            updateAppStore(responseData)
          },
        })
        return
      case isCreateRequest:
        const responseCreate = await createCart({
          siteId,
          data: { ...cartData, rooms: makeLeaseRoomsGreatAgain(cartData.rooms) },
        })
        updateLocaleState(responseCreate.data.data)
        dispatch(UPDATE_CART_CONNECTION(responseCreate.data.database_siteId))

        processApiResponse(responseCreate as ApiResponse, {
          error: 'Create cart error',
        })
        return
      case isDeleteRequest:
        await handleDeleteCart()
        return
      default:
        return
    }
  }

  return {
    updateCartContent,
    fetchCartsData,
    fetchCartData,
    deleteCart: handleDeleteCart,
    handleDeleteSingleCart,
    handleCreateSingleCart,
    handleUpdateSingleCart,
    toggleMainCart,
    setActiveBooking,
    updateActiveLeaseConnection,
    updateAppStore,
    bookingCart,
    storageCart,
    mailboxCart,
    recurringCarts,
    cart,
    activeBooking,
    cartAmount,
  }
}
