import type { FC } from 'react'
import { useCallback } from 'react'

import { useCartMonitor } from 'app/providers/index'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import { useCartManager } from 'features/Cart/hooks/useCartManager'
import type { ICartOption } from 'features/Cart/interfaces/ICartOption'
import type { IOptionSlot } from 'features/Option'
import { OptionFixedService } from 'features/Option'
import { UPDATE_OPTION_TYPE_FIXED } from 'features/Option/state/slices/optionSlice'
import { CartSlot } from 'features/Cart/components/CartSlot/CartSlot'
import { CartItem } from 'features/Cart/components/CartItem/CartItem'

const { removeDayInOption } = OptionFixedService

export const CartOptionFixedList: FC<ICartOption> = ({
  handleEdit,
  handleUpdate,
  updateCartPrice,
}) => {
  const { isUpdatingCart } = useCartMonitor()
  const dispatch = useAppDispatch()
  const { newLeaseRooms: rooms } = useAppSelector((state) => state.leaseReducer)
  const {
    optionTypeSlots: optionSlots,
    optionTypeFixed: optionFixed,
    optionSelectFixed,
  } = useAppSelector((state) => state.optionReducer)

  const { bookingCart } = useCartManager()

  const handleRemoveOptionFixed = useCallback(
    (date: string, option: ISiteRoom): void => {
      const updatedOptionFixed: IOptionSlot[] = removeDayInOption(date, option.id, optionFixed)
      dispatch(UPDATE_OPTION_TYPE_FIXED(updatedOptionFixed))
      updateCartPrice({
        rooms,
        optionSelectFixed,
        options: [...optionSlots, ...updatedOptionFixed],
      })
      handleUpdate({ optionFixed: updatedOptionFixed })
    },
    [rooms, optionSlots, optionSelectFixed, bookingCart, isUpdatingCart, optionFixed],
  )

  if (!optionFixed?.length) return null

  return (
    <div className='cart-card'>
      <hr />
      {optionFixed.map(({ option, price, selectedDays }) => (
        <CartItem
          price={price}
          name={option.name}
          handleEdit={() => handleEdit(option)}
          img={option.images.at(0)}>
          {selectedDays.map((day) => (
            <CartSlot
              key={day.date}
              day={day}
              option={option}
              handleRemoveOption={handleRemoveOptionFixed}
            />
          ))}
        </CartItem>
      ))}
    </div>
  )
}
