import { useEffect, useMemo } from 'react'

import { useAuth } from 'app/providers/auth/AuthProvider'

import type { ILevels } from 'common/interfaces/ILevels'
import { useLazyFetchLevelsQuery } from 'common/store/api/rootApi'

interface IProps {
  levels: ILevels
  isFetching: boolean
}

export const useSitePoints = (): IProps => {
  const { user } = useAuth()

  const [fetchLevels, { data: levels, isFetching }] = useLazyFetchLevelsQuery()

  useEffect(() => {
    if (user) {
      fetchLevels()
    }
  }, [user])

  return useMemo(() => ({ levels, isFetching }), [levels, isFetching])
}
