import type { FC } from 'react'
import React, { memo, useEffect, useMemo } from 'react'

import { useAuth } from 'app/providers'

import { BOOKING_TYPE } from 'features/Booking/constants/bookingType'
import {
  useLazyFetchBookingsQuery,
  useLazyFetchBookingTypesQuery,
} from 'features/Booking/state/api/bookingApi'

import styles from './dashboard.module.scss'
import { ChooseBookingType } from './UserDashboard/ChooseBookingType/ChooseBookingType'
import { ActiveBookings } from './UserDashboard/ActiveBookings/ActiveBookings'
import { GuestDashboard } from './GuestDashboard/GuestDashboard'
import { useFetchProfileQuery } from 'features/SettingsOld/state/api/settingsApi'
import { useParams } from 'react-router-dom'
import { Spinner } from 'common/components/Spinner'
import Show from 'common/components/Show2'

export const Dashboard: FC = memo(() => {
  const { hasCompletedDocuments, user: authUser, isDoctor, isSuperStaff } = useAuth()
  const { data: user, isLoading: isLoadingUser } = useFetchProfileQuery(null, { skip: !authUser })
  const { siteId } = useParams()

  const [fetchBookings, { data: bookings, isFetching: isFetchingBooking }] =
    useLazyFetchBookingsQuery()
  const [fetchBookingTypes, { data: bookingTypes, isFetching: isFetchingBookingTypes }] =
    useLazyFetchBookingTypesQuery({
      refetchOnFocus: true,
    })

  const isNotGuest = useMemo(() => hasCompletedDocuments || user, [hasCompletedDocuments, user])

  const isLoading = useMemo(
    () => isFetchingBooking || isFetchingBookingTypes || isLoadingUser,
    [isFetchingBooking, isFetchingBookingTypes, isLoadingUser],
  )

  useEffect(() => {
    if (!siteId || !user) {
      return
    }

    fetchBookings({ type: BOOKING_TYPE.ONGOING, siteId })
    fetchBookingTypes(siteId)
  }, [siteId, user])

  return (
    <Show>
      <Show.When isVisible={!!user || !isLoadingUser}>
        <div className={styles.homeContainer} data-non-auth={!isNotGuest}>
          {isNotGuest ? (
            <>
              <Show.When isVisible={isDoctor || isSuperStaff}>
                <ChooseBookingType isLoading={isLoading} bookingTypes={bookingTypes} />
              </Show.When>
              <ActiveBookings isLoading={isLoading} bookings={bookings} />
            </>
          ) : (
            <GuestDashboard />
          )}
        </div>
      </Show.When>
      <Show.Else>
        <div className='content-page-wrapper'>
          <Spinner size='large' isLoading={true} />
        </div>
      </Show.Else>
    </Show>
  )
})

export type DashboardType = typeof Dashboard

Dashboard.displayName = 'Dashboard'
