import './checkout.scss'

import React, { type FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'

import { Col, type RadioChangeEvent, Row, Spin } from 'antd'

import { useAuth, useCartMonitor, useNotification } from 'app/providers'

import { Show } from 'common/components/Show/Show'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ApiResponse } from 'common/interfaces/IRequestResponse'
import { getMultipleSuffix } from 'common/utils/text.utils'

import { PAYMENT_CONSTANTS } from 'features/Payment'
import { PaymentBilling } from 'features/Payment/components/PaymentBilling/PaymentBilling'
import { PaymentPrice } from 'features/Payment/components/PaymentPrice/PaymentPrice'
import {
  useCheckoutPromotionMutation,
  useGetPromotionSignContractsQuery,
} from 'features/Home/Promotion/state/promotionsApi'
import { updateSignature } from 'features/Home/Promotion/state/promotionsSlice'
import type { ISettingPaymentCard } from 'features/SettingsOld/interfaces/ISettingPaymentCard'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { useLazyFetchPaymentMethodsQuery } from 'features/SettingsOld/state/api/settingsApi'
import { PaymentCardList } from 'features/Profile/features/Payment/components/PaymentCardList/PaymentCardList'
import { PaymentSettingsNavigate } from 'features/Payment/components/PaymentSettingsNavigate/PaymentSettingsNavigate'
import { PathUtils } from 'routes/routes.utils'

export const Checkout: FC = memo(() => {
  const [selectedMethod, setSelectedMethod] = useState<ISettingPaymentCard>(null)
  const [hasPhoneNumber, setHasPhoneNumber] = useState<boolean>(false)
  const { notification } = useNotification()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { user } = useAuth()
  const { bookingId, siteId } = useParams()
  const { processApiResponse } = useApiResponse()
  const { isUpdatingCart, isFetchingCart } = useCartMonitor()

  const { signature } = useAppSelector((state) => state.promotionReducer)

  const { data, isLoading, isError } = useGetPromotionSignContractsQuery({ bookingId, siteId })

  const [checkoutPromotion, { isLoading: isCheckoutLoading }] = useCheckoutPromotionMutation()

  const [fetchPaymentMethods, { data: paymentMethodsData, isFetching }] =
    useLazyFetchPaymentMethodsQuery()

  const { paymentMethods = [], default: defaultMethod } = paymentMethodsData ?? {}

  const payment = useMemo(() => data?.summary?.payment, [data?.summary?.payment])

  useEffect(() => {
    setHasPhoneNumber(!!user.phone_number)
  }, [user])

  const handleSubmit = async () => {
    const response = await checkoutPromotion({
      siteId,
      bookingId,
      signature,
      paymentMethodId: selectedMethod.id,
      billing_details: selectedMethod.billingDetails,
    })

    processApiResponse(response as ApiResponse, {
      error: 'Checkout error!',
      success: 'Checkout success!',
      successCallback: () => {
        dispatch(updateSignature(null))
        navigate(PathUtils.getPromotionDetails(siteId, bookingId))
      },
    })
  }

  const isCheckoutDisabled = (() => {
    return isLoading || isCheckoutLoading || !hasPhoneNumber || !selectedMethod || !signature
  })()

  if (isError) {
    return <Navigate to={PATH_SEGMENT.ROOT} replace />
  }

  const getPaymentLabel = () => {
    const payment = data?.summary?.payment
    if (payment?.payment_method === 'entire_amount')
      return `Total (${data?.summary?.payment?.promotion_weeks} weeks)`
    return `Due today (including taxes & deposit)`
  }

  useEffect(() => {
    if (!siteId) {
      return
    }

    fetchPaymentMethods()
  }, [siteId])

  useEffect(() => {
    if (defaultMethod) {
      const card = paymentMethods.find((method) => method.id === defaultMethod)

      if (card) {
        setSelectedMethod(card)
      }
    }
  }, [defaultMethod])

  const changePaymentMethodHandler = useCallback(
    (e: RadioChangeEvent) => {
      const card = paymentMethods.find((method) => method.id === e.target.value)
      setSelectedMethod(card)
    },
    [paymentMethods],
  )

  return (
    <Spin spinning={isLoading || isUpdatingCart || isFetchingCart || isCheckoutLoading}>
      {notification}
      <Row className='promotion-checkout-container'>
        <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
          <section className='payment-wrapper'>
            <h2 className='section-title'>{PAYMENT_CONSTANTS.METHOD}</h2>
            <div className='payment-content'>
              <PaymentCardList
                isFetching={isFetching}
                list={paymentMethods}
                selected={selectedMethod?.id}
                handleChangeMethod={changePaymentMethodHandler}
              />
              <PaymentSettingsNavigate description={PAYMENT_CONSTANTS.MANAGE_PAYMENT} />
            </div>

            <Show when={!hasPhoneNumber}>
              <div className='incomplete-details'>
                <h3 className='title'>{PAYMENT_CONSTANTS.INCOMPLETE_DETAILS_TITLE}</h3>
                <p className='description'>
                  {PAYMENT_CONSTANTS.INCOMPLETE_DETAILS_DESCRIPTION}
                  <Link to='/settings/profile'>{PAYMENT_CONSTANTS.ACCOUNT_SETTINGS}</Link>
                  {PAYMENT_CONSTANTS.COMPLETE_IT}
                </p>
              </div>
            </Show>
            <PaymentBilling
              handleGoHome={() => navigate(PathUtils.getHome(siteId))}
              isDisabled={isCheckoutDisabled}
              handleSubmit={handleSubmit}
              isLoading={isCheckoutLoading}
            />
          </section>
        </Col>
        <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
          <section className='billing-details-wrapper'>
            <h2 className='section-title'>Promotion Details</h2>
            <div className='items-container'>
              <div className='item'>
                {data?.summary?.convention?.rooms?.map((room, i) => {
                  const hasWeekdaySlots = room.weekday_slots > 0
                  const hasWeekendSlots = room.weekend_slots > 0

                  return (
                    <React.Fragment key={`${room.id}_${i}`}>
                      <p className='item-title'>{room.name}</p>
                      <div>
                        {hasWeekdaySlots && (
                          <p className='item-description'>
                            {`Any ${room.weekday_slots} ${getMultipleSuffix(
                              room.weekday_slots,
                              'slot',
                            )}  from Mon to Fri \n(${room.slot_period})`}
                          </p>
                        )}
                        {hasWeekendSlots && (
                          <p className='item-description'>
                            {`Any ${room.weekend_slots} ${getMultipleSuffix(
                              room.weekend_slots,
                              'slot',
                            )} from Sat to Sun \n(${room.slot_period})`}
                          </p>
                        )}
                      </div>
                    </React.Fragment>
                  )
                })}
                {data?.summary?.convention?.options?.map(({ name }, i) => {
                  return (
                    <p key={`${name}_${i}`} className='item-title'>
                      {name}
                    </p>
                  )
                })}
              </div>
            </div>
            <h2 className='section-title'>Payment</h2>
            <PaymentPrice
              limit={0}
              totalLabel={getPaymentLabel()}
              amount={payment}
              className='p-0'
              nextAmount={payment?.next_payment}
              nextAmountLabel={
                payment?.payment_method === '4_weeks'
                  ? 'Next recurrent payment (each 4 weeks)'
                  : undefined
              }
            />
          </section>
        </Col>
      </Row>
    </Spin>
  )
})
