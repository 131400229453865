import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { PusherProvider } from 'app/providers/pusher/PusherProvider'

import { TabPanel } from 'common/components/TabPanel/TabPanel'
import { Staff } from 'features/Home/Book/Booking/Staff/Staff'
import type { FC } from 'react'
import { memo, useEffect, useMemo } from 'react'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'
import { promotionConfig } from './config'
import { Documents } from './Documents/Documents'
import { PromotionDetails } from './PromotionDetails/PromotionDetails'
import { useLazyFetchPromotionsInfoQuery } from './state/promotionsApi'
import { Notifications } from './Notifications/Notifications'
import { Contracts } from './Contracts/Contracts'
import { usePrevious } from 'react-use'
import Schedule from 'features/Home/Book/Booking/Schedule/Schedule'
import { BOOKING_STATUS_CODE } from 'common/constants/bookingConstants'
import { Spinner } from 'common/components/Spinner'
import { Show } from 'common/components/Show/Show'
import { Shop } from 'features/Home/Book/Booking/Shop/Shop'

export const Promotion: FC = memo(() => {
  const navigate = useNavigate()

  const { bookingId, siteId } = useParams()
  const previousSiteId = usePrevious(siteId)

  const [fetchPromotion, { data: promotion, isLoading, isFetching, isSuccess }] =
    useLazyFetchPromotionsInfoQuery()

  const isUnsigned = useMemo(
    () => promotion?.status_code === BOOKING_STATUS_CODE.WAITING_TO_SIGN,
    [promotion],
  )

  const routesConfig = useMemo(() => {
    const baseRoutes = {
      [PATH_SEGMENT.ROOT]: (
        <Navigate to={isUnsigned ? PATH_SEGMENT.CONTRACTS : PATH_SEGMENT.DETAILS} replace />
      ),
    }

    const additionalRoutes = isUnsigned
      ? {
          [PATH_SEGMENT.CONTRACTS]: <Contracts promotion={promotion} />,
        }
      : {
          [`${PATH_SEGMENT.DETAILS}/*`]: (
            <PromotionDetails promotion={promotion} isLoading={isLoading} isSuccess={isSuccess} />
          ),
          [PATH_SEGMENT.SCHEDULE]: <Schedule booking={promotion} />,
          [`${PATH_SEGMENT.SCHEDULE}/:day`]: <Schedule booking={promotion} />,
          [PATH_SEGMENT.STAFF]: <Staff />,
          [PATH_SEGMENT.CONTRACTS]: <Contracts promotion={promotion} />,
          [PATH_SEGMENT.NOTIFICATIONS]: (
            <Notifications promotion={promotion} isLoading={isLoading} />
          ),
          [`${PATH_SEGMENT.DOCUMENTS}/*`]: <Documents />,
          [PATH_SEGMENT.SHOP]: <Shop isPromotion />,
        }

    return { ...baseRoutes, ...additionalRoutes }
  }, [promotion, isLoading, isSuccess, isUnsigned])

  useEffect(() => {
    if (!bookingId || !siteId) {
      return
    }

    fetchPromotion({ bookingId, siteId })
  }, [bookingId, siteId])

  useEffect(() => {
    if (!!siteId && !!previousSiteId && siteId !== previousSiteId) {
      navigate(PATH_SEGMENT.ROOT)
    }
  }, [siteId, previousSiteId])

  return (
    <Show
      when={!!promotion && !isFetching && !isLoading}
      fallback={
        <div className='content-page-wrapper'>
          <Spinner size='large' isLoading={true} />
        </div>
      }>
      <PusherProvider selectedSite={siteId} selectedLease={bookingId}>
        <>
          {!isUnsigned && <TabPanel tabs={promotionConfig(promotion?.status_code).tabs} />}
          {generateRouter(routesConfig)}
        </>
      </PusherProvider>
    </Show>
  )
})
