export enum BOOKING_TYPE {
  BOOKING = 'Room',
  MAILBOX = 'Mailbox',
  STORAGE = 'Storage',
  RECURRING = 'Recurring',
}

export const LeasesTypeArr = Object.values(BOOKING_TYPE)

export enum BOOKING_PARAMS {
  TYPE = 'type',
  BOOKING_ID = 'bookingId',
}

export enum BOOKING_TYPE_CODE {
  BOOKING = 1,
  MAILBOX = 2,
  LOCKER_HALF_HEIGHT = 3,
  MEDICAL_CART = 4,
  REFRIGERATOR = 5,
  STORAGE = 6,
  DOUBLE_DOOR_CABINET = 7,
  LOCKER_FULL_HEIGHT = 8,
  EHR = 9,
}

export const getBookingTypeFromCode = (code: number): BOOKING_TYPE => {
  switch (code) {
    case BOOKING_TYPE_CODE.BOOKING:
      return BOOKING_TYPE.BOOKING
    case BOOKING_TYPE_CODE.MAILBOX:
      return BOOKING_TYPE.MAILBOX
    case BOOKING_TYPE_CODE.STORAGE:
      return BOOKING_TYPE.STORAGE
    default:
      return BOOKING_TYPE.RECURRING
  }
}

export enum OPTION_TYPE_CODE {
  REFRIGERATOR = 1,
  DATA_LOGGER_REFRIGERATOR = 2,
  AUTOCLAVE = 3,
  CENTRIFUGE = 4,
  PATIENT_ACCESS_SPECIMEN_ROOM = 5,
  MOBILE_STORAGE_CART = 9,
  LOCKER_HALF_HEIGHT = 10,
  LAB_PICKUP = 12,
  LOCKER_FULL_HEIGHT = 30,
  MICROSCOPE = 85,
  DOUBLE_DOOR_CABINET = 90,
}
