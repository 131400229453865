import type { JSX } from 'react'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { useToggle } from 'react-use'

import { useAuth } from 'app/providers/auth/AuthProvider'

import {
  useCartManager,
  useCreateCartMutation,
  useDeleteCartMutation,
  useUpdateCartMutation,
} from 'features/Cart'
import {
  useCreateSingleCartMutation,
  useDeleteSingleCartMutation,
  useLazyFetchCartsQuery,
  useUpdateSingleCartMutation,
} from 'features/Cart/state/api/cartApi'
import {
  useCreateShopCartMutation,
  useDeleteShopCartMutation,
  useUpdateShopCartMutation,
} from 'features/Option'
import { useParams } from 'react-router-dom'
import type { ApiResponse } from 'common/interfaces/IRequestResponse'
import { BOOKING_TYPE_CODE } from 'features/Booking/constants/booking.constants'
import { updateCartState } from 'features/Cart/state/slices/cartSlice'
import { useAppDispatch } from 'common/hooks/redux'
import { useApiResponse } from 'common/hooks/useApiResponse'

interface ICartOut {
  isFetchingCart: boolean
  isUpdatingCart: boolean
  toggleIsDebouncing: () => void
}

const CartContext = createContext<ICartOut>({
  isFetchingCart: false,
  isUpdatingCart: false,
  toggleIsDebouncing: () => undefined,
})

export const CartProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth()

  const { siteId } = useParams()
  const { user } = auth ?? {}
  const dispatch = useAppDispatch()
  const { updateAppStore } = useCartManager()
  const { processApiResponse } = useApiResponse()

  const [isDebouncing, toggleIsDebouncing] = useToggle(false)
  const [_update, { isLoading: isUpdating }] = useUpdateCartMutation({
    fixedCacheKey: 'updating-cart',
  })

  const [_delete, { isLoading: isDeleting }] = useDeleteCartMutation({
    fixedCacheKey: 'deleting-cart',
  })

  const [_create, { isLoading: isCreating }] = useCreateCartMutation({
    fixedCacheKey: 'creating-cart',
  })

  const [_createShop, { isLoading: isCreatingShop }] = useCreateShopCartMutation({
    fixedCacheKey: 'creating-shop-cart',
  })
  const [_updateShop, { isLoading: isUpdatingShop }] = useUpdateShopCartMutation({
    fixedCacheKey: 'updating-shop-cart',
  })

  const [_deleteShop, { isLoading: isDeletingShop }] = useDeleteShopCartMutation({
    fixedCacheKey: 'deleting-shop-cart',
  })
  const [_deleteSingleCart, { isLoading: isDeletingSingleCart }] = useDeleteSingleCartMutation({
    fixedCacheKey: 'deleting-single-cart',
  })
  const [_creatingSingleCart, { isLoading: isCreatingSingleCart }] = useCreateSingleCartMutation({
    fixedCacheKey: 'creating-single-cart',
  })
  const [_updatingSingleCart, { isLoading: isUpdatingSingleCart }] = useUpdateSingleCartMutation({
    fixedCacheKey: 'updating-single-cart',
  })

  const [fetchCarts, { isLoading: isFetchingCarts }] = useLazyFetchCartsQuery()

  const fetchCartsData = async (): Promise<void> => {
    if (!user) return
    const response = await fetchCarts(siteId)
    processApiResponse(response as ApiResponse, {
      successCallback: () => {
        const responseData = response.data
        dispatch(updateCartState({ key: 'cart', value: responseData }))
        updateAppStore(responseData[BOOKING_TYPE_CODE.BOOKING])
      },
    })
  }

  useEffect(() => {
    if (user && siteId) {
      fetchCartsData()
    }
  }, [])

  const isUpdatingCart: boolean[] = [
    isCreating,
    isUpdating,
    isDeleting,
    isDebouncing,
    isUpdatingShop,
    isCreatingShop,
    isDeletingShop,
    isDeletingSingleCart,
    isFetchingCarts,
    isCreatingSingleCart,
    isUpdatingSingleCart,
  ]

  const value: ICartOut = useMemo(
    () => ({
      isFetchingCart: isFetchingCarts,
      toggleIsDebouncing,
      isUpdatingCart: isUpdatingCart.includes(true),
    }),
    [isUpdatingCart, isFetchingCarts],
  )

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export const useCartMonitor = () => {
  return useContext(CartContext)
}
