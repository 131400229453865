import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import type { ICartContent, ICartOptionContent } from 'common/interfaces/ICartContent'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type { CheckoutDTO, ICartOptionData } from 'features/Cart/interfaces/ICart'
import type { BookingType } from 'features/Home/interfaces/IInfo'
import type { IProviderCondition } from 'features/Provider'

export const cartApi = createApi({
  reducerPath: REDUCERS.CART,
  tagTypes: ['Cart'],
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchContract: build.query<IProviderCondition, string>({
      query: (siteId) => ({
        url: '/contract-template',
        headers: { 'Database-Connection': siteId },
      }),
    }),

    fetchCarts: build.query<Record<number, ICartOptionContent>, string>({
      query: (siteId) => ({
        url: '/carts',
        headers: { 'Database-Connection': siteId },
      }),
      providesTags: ['Carts'],
    }),

    fetchCart: build.query<ICartContent, string>({
      query: (siteId) => ({
        url: '/cart',
        headers: { 'Database-Connection': siteId },
      }),
      providesTags: ['Cart'],
    }),

    deleteCart: build.mutation<undefined, string>({
      query: (siteId) => ({
        url: '/cart',
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': siteId },
      }),
      invalidatesTags: ['Carts'],
    }),

    updateCart: build.mutation({
      query: ({ data, siteId }) => ({
        data,
        url: '/cart',
        method: HTTP_METHODS.PUT,
        headers: { 'Database-Connection': siteId },
      }),
      invalidatesTags: ['Carts'],
    }),

    createCart: build.mutation({
      query: ({ data, siteId }) => ({
        data,
        url: '/cart',
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
      invalidatesTags: ['Carts'],
    }),

    checkoutCart: build.mutation({
      query: ({ data, siteId }) => ({
        data,
        url: '/checkout',
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: ({ item }: { item: BookingType }) => item,
      invalidatesTags: ['Levels', 'Carts'],
    }),
    // other bookings
    createSingleCart: build.mutation<
      ICartOptionContent,
      {
        data: Partial<ICartOptionData>
        request_url_id: number
        siteId: string
      }
    >({
      query: ({ data, siteId, request_url_id }) => ({
        data,
        url: `/booking/${request_url_id}/cart`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    fetchSingleCart: build.query<ICartOptionContent, { request_url_id: number; siteId: string }>({
      query: ({ request_url_id, siteId }) => ({
        url: `/booking/${request_url_id}}/cart`,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    deleteSingleCart: build.mutation<undefined, { request_url_id: number; siteId: string }>({
      query: ({ request_url_id, siteId }) => ({
        url: `/booking/${request_url_id}/cart`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    fetchContractTemplate: build.query<
      IProviderCondition,
      { request_url_id: number; siteId: string }
    >({
      query: ({ request_url_id, siteId }) => ({
        url: `/booking/${request_url_id}/contract-template`,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    checkoutSingleCart: build.mutation<
      BookingType,
      {
        data: CheckoutDTO
        request_url_id: number
        siteId: string
      }
    >({
      query: ({ data, request_url_id, siteId }) => ({
        data,
        url: `/booking/${request_url_id}/checkout`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: ({ item }: { item: BookingType }) => item,
      invalidatesTags: ['Levels'],
    }),

    fetchPostalFormContract: build.query<
      IProviderCondition,
      { request_url_id: number; siteId: string }
    >({
      query: ({ request_url_id, siteId }) => ({
        url: `/booking/${request_url_id}/postal-form-template`,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    updateSingleCart: build.mutation<
      ICartOptionContent,
      {
        data: Partial<ICartOptionData>
        request_url_id: number
        siteId: string
      }
    >({
      query: ({ data, request_url_id, siteId }) => ({
        data,
        url: `/booking/${request_url_id}/cart`,
        method: HTTP_METHODS.PUT,
        headers: { 'Database-Connection': siteId },
      }),
    }),
  }),
})

export const {
  // common
  useFetchCartsQuery,
  useLazyFetchCartsQuery,
  // main lease
  useFetchContractQuery,
  useLazyFetchContractQuery,
  useLazyFetchCartQuery,
  useDeleteCartMutation,
  useUpdateCartMutation,
  useCreateCartMutation,
  useCheckoutCartMutation,
  // other lease (mailbox, storage etc)
  useCheckoutSingleCartMutation,
  useCreateSingleCartMutation,
  useDeleteSingleCartMutation,
  useFetchSingleCartQuery,
  useLazyFetchSingleCartQuery,
  useUpdateSingleCartMutation,
  useFetchContractTemplateQuery,
  useLazyFetchContractTemplateQuery,
  useLazyFetchPostalFormContractQuery,
  useFetchPostalFormContractQuery,
} = cartApi
