import type { FC, JSX } from 'react'
import { Fragment } from 'react'

import type {
  IRoomSelectedDay,
  IRoomSelectedTimeSlot,
  IRoomTimeSlot,
} from 'features/Booking/interfaces/ILeaseInfoRoom'
import { CartRoomSlot } from 'features/Cart/components/CartRoomSlot/CartRoomSlot'

import styles from './cartRoomDay.module.scss'

interface IProps {
  day: IRoomSelectedDay
  handleRemoveSlot: (slot: IRoomTimeSlot, date: string) => void
}

export const CartRoomDay: FC<IProps> = ({ day, handleRemoveSlot }) => {
  if (!day?.selectedSlots?.length) return null
  return (
    <div className={styles.parent}>
      {day.selectedSlots.map((timeSlot: IRoomSelectedTimeSlot): JSX.Element => {
        return (
          <Fragment key={timeSlot.id}>
            <CartRoomSlot date={day.date} timeSlot={timeSlot} handleRemoveSlot={handleRemoveSlot} />
          </Fragment>
        )
      })}
    </div>
  )
}
