import './roomAvailability.scss'

import { useMemo } from 'react'
import { groupSlots } from 'features/Booking/components/BookingSlots/booking.utils'
import type { PromotionRoomAvailability } from 'features/Home/Promotion/types/promotion.types'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BookingSlotGroup } from 'features/Booking/components/BookingSlots/BookingSlotGroup'

type Props = {
  room: PromotionRoomAvailability
  selectedSlotIds: number[]
  handleSlotToggle: (roomId: number, slotId: number) => void
  handleGroupToggle: (roomId: number, groupSlotIds: number[]) => void
  checkHasReachedMaxSlots: () => boolean
}

export const RoomBookingSlots = ({
  room,
  selectedSlotIds,
  handleSlotToggle,
  handleGroupToggle,
  checkHasReachedMaxSlots,
}: Props) => {
  const groupedSlots = useMemo(() => {
    return groupSlots(room.slots.map((slot) => ({ ...slot, title: slot.name })))
  }, [room.slots])

  const isGroupedSelection = useMemo(() => room.slot_usage_code === 1, [room.slot_usage_code])

  const getIsGroupDisabledGrouped = (groupName: string) => {
    const slots = groupedSlots[groupName]

    const hasSelectedSlotsInGroup = slots.some((slot) => selectedSlotIds.includes(slot.id))
    // allow to deselect current group if this reached the max slots
    if (hasSelectedSlotsInGroup) return false

    const hasReachedMaxSlots = checkHasReachedMaxSlots()
    if (hasReachedMaxSlots) return true

    return slots.some((slot) => !slot.is_active)
  }

  const getIsGroupDisabledAnytime = (groupName: string) => {
    const slots = groupedSlots[groupName]

    const hasSelectedSlotsInGroup = slots.some((slot) => selectedSlotIds.includes(slot.id))
    if (hasSelectedSlotsInGroup) {
      return false
    }

    const hasReachedMaxSlots = checkHasReachedMaxSlots()
    if (hasReachedMaxSlots) {
      return true
    }

    return slots.every((slot) => !slot.is_active)
  }

  const getIsGroupDisabled = (groupName: string) => {
    if (isGroupedSelection) {
      return getIsGroupDisabledGrouped(groupName)
    }

    return getIsGroupDisabledAnytime(groupName)
  }

  const checkIsSlotForceDisabled = (groupName: string, slotId: number) => {
    // if grouped selection, all slots are force disabled, just parent can select slots
    if (isGroupedSelection) return true

    // if slot is checked, allow it to be unchecked (if max slots reached)
    if (selectedSlotIds.includes(slotId)) return false

    return checkHasReachedMaxSlots()
  }

  return (
    <div key={room.id} className='item-container'>
      <div className='room-header'>
        <img src={room?.images[0]} className='image' alt={ALT_CONSTANTS.ROOM_PHOTO} />
        <div className='content-container'>
          <h2 className='title'>{room?.name}</h2>
        </div>
      </div>
      <div className='time-slot-wrapper'>
        {Object.entries(groupedSlots).map(([groupName, slots]) => (
          <BookingSlotGroup
            key={groupName}
            hasIntervalTitle
            groupName={groupName}
            slots={slots}
            hasExpand={!isGroupedSelection}
            selectedSlots={selectedSlotIds}
            isGroupDisabled={getIsGroupDisabled(groupName)}
            getIsSlotForceDisabled={(slotId) => checkIsSlotForceDisabled(groupName, slotId)}
            onSlotToggle={(slotId) => handleSlotToggle(room.id, slotId)}
            onGroupToggle={(groupSlotIds) => handleGroupToggle(room.id, groupSlotIds)}
          />
        ))}
      </div>
    </div>
  )
}
